import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Root from './Root';
import TicTacGodBot from './TicTacGodBot';
import Home from './Home';

const router=createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/Home',
        element: <Home />,
      },
      {
        path: '/TicTacGodBot',
        element: <TicTacGodBot />,
      },
    ],
  },
]);

export default router;